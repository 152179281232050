@import "settings";

.Home {
  padding: $gutter-3 $gutter-2;
  @media screen and (min-width: 768px) {
    max-width: 668px;
    margin: 0 auto;
  }

  &__image {
    width: 100%;
    margin-bottom: $gutter-2;

    @media screen and (min-width: 768px) {
      width: 50%;
      float: left;
      margin-right: $gutter-2
    }
  }

  &__presentation {
    margin-bottom: $gutter-3;
    em {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  &__action {
    display: flex;
    justify-content: space-evenly;

    &__button {
      border: $border-size-1 solid $color-1;
      padding: $gutter-1 $gutter-2;
      background-color: $color-2;
      color: $color-3;
      transition: all $duration-2;
      box-shadow: $shadow-1;
      cursor: pointer;


      &:hover {
        transition: all $duration-1;
        background-color: $color-5;
        color: $color-1;
      }
    }
  }
}
