@import "settings";

.Projects {
  &__item {
    padding: $gutter-1;

    &__container {
      display:flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        max-width: 668px;
        margin: 0 auto;
      }
    }
    
    &:not(:last-child) {
      border-bottom: $border-size-1 solid $color-1;
      padding-bottom: $gutter-3 * 2;
    }

    &__title {
      text-shadow: $shadow-1;
    }

    &__title,
    &__year {
      text-align: center;
    }

    &__preview {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: $gutter-3 0;

      &__desktop {
        width: 70%;
      }

      &__mobile {
        width: 20%;
      }
    }

    &__description {
      margin-bottom: $gutter-2;
      text-align: justify;
      text-indent: $gutter-3;
    }

    &__stack {
      font-weight: bold;
      margin-right: $gutter-1;

      &__techno {
        margin-right: $gutter-1;

        &:not(:last-child) {
          margin-right: $gutter-1;
          
          &:after{
            content: ",";
          }
        }
      }
    }

    &__stackList {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &__stackList,
    &__stack {
      text-align: center;
      text-transform: capitalize;
    }

    &__button {
      border: $border-size-1 solid $color-1;
      padding: $gutter-1 $gutter-2;
      background-color: $color-2;
      color: $color-3;
      transition: all $duration-2;
      box-shadow: $shadow-1;
      cursor: pointer;
      min-width: 30%;
      margin: auto;
      text-align: center;
      margin-top: $gutter-3;
      margin-bottom: $gutter-2;

      &:hover {
        transition: all $duration-1;
        background-color: $color-5;
        color: $color-1;
      }
    }
  }
}
