@import "settings";

.Study {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__content {

    &:not(:last-child) {
      margin-bottom: $gutter-3;
    }

    &__title:before {
      content: url("../assets/studies.svg");
      margin-right: $gutter-1;
    }

    &__dates {
      text-transform: lowercase;
      font-style: italic;
      margin-bottom: $gutter-1;
    }
  }

  &__schoolLogo {
      width: 100px;
      filter: grayscale(100%);
      transition: all $duration-2;

      &:hover {
        transition: all $duration-1;
        filter: none;
      }
  }


}
