@import 'settings';

.Career {
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  &__jobs {
    @media screen and (min-width: 1200px) {
      width: 70%;
      margin: auto;
      margin-bottom: $gutter-3;
      border-bottom: $border-size-1 solid $color-2;
    }
    padding: $gutter-1;

    &__title {
      text-align: center;
      text-shadow: $shadow-1;
    }
  }

  &__studies {
    @media screen and (min-width: 1200px) {
      width: 50%;
      border-right: $border-size-1 solid $color-2;
    }
    padding: $gutter-1;

    &__title {
      text-align: center;
      text-shadow: $shadow-1;
    }
  }

  &__degrees {
    @media screen and (min-width: 1200px) {
      width: 50%
    }
    padding: $gutter-1;

    &__title {
      text-align: center;
      text-shadow: $shadow-1;
    }
  }
}