@import 'settings';

.App {
  border: $border-size-1 solid $color-2;
  z-index: 99;
  min-height: 100vh;

  &__content {
    // min-height:80%;
    margin-bottom: 20vh;

    @media screen and (min-width: 767px) {
      margin-bottom: 0;
      padding-bottom: 11%;
    }

    @media screen and (min-height: 1000px) and (min-width:768px) {
      padding-bottom: 15%;
      margin-bottom: 0;
    }
  }
}