@import "settings";

.NotFound {
  padding: $gutter-3 $gutter-2;
  @media screen and (min-width: 768px) {
    max-width: 668px;
    margin: 0 auto;
    
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  &__error {
    font-family: $font-2;
    font-size: 8rem;
  }

  &__message {
    text-align: center;
    margin-bottom: $gutter-3;
  }

  &__button {
    border: $border-size-1 solid $color-1;
    padding: $gutter-1 $gutter-2;
    background-color: $color-2;
    color: $color-3;
    transition: all $duration-2;
    box-shadow: $shadow-1;
    cursor: pointer;

    &:hover {
      transition: all $duration-1;
      background-color: $color-5;
      color: $color-1;
    }
  }
}
