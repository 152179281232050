@import "settings";

.Degree {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: $gutter-3;
  }

  &__content {
    
    &__title:before {
      content: url("../assets/certificate.svg");
      margin-right: $gutter-1;
    }

    &__year {
      font-style: italic;
      margin-bottom: $gutter-1;
    }
  }

  &__labelLogo {
    width: 100px;
    filter: grayscale(100%);
    transition: all $duration-2;

    &:hover {
      transition: all $duration-1;
      filter: none;
    }
}
}
