@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  // &:focus {
  //   outline: none;
  // }
}

a {
  text-decoration: none;
  color: inherit;
}

$font-1: 'Courier Prime', monospace;
$font-2: 'Playfair Display SC', serif;;

$color-1: #414141;
$color-2: #535353;
$color-3: #FFFF;
$color-4: #C4C4C450;
$color-7: #C4C4C4;
$color-5: #FFFBF1;
$color-6: #676CEF;
$color-8: #a09b9b;
$color-error:#F85353;
$color-success: #88BA62;
$gutter-1: 0.5rem;
$gutter-2: 1rem;
$gutter-3: 1.5rem;
$border-size-1: 3px;
$duration-1: 0.6s;
$duration-2: 1.8s;
$shadow-1: 0px 5px 4px #41414150;

body {
  background: url('../assets/rice-paper-2.png');
  background-attachment: fixed;
  background-size: auto;
  font-family: $font-1;
  color: $color-1;
  padding: $gutter-1;
  overflow-y: scroll;
}

h2 {
  font-family: $font-2;
  text-decoration: underline;
  margin-bottom: $gutter-2
}
