@import "settings";

.Contact {
  padding: $gutter-3 $gutter-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    max-width: 668px;
    margin: 0 auto;
  }
  label {
    align-self: start;
  }

  &__field {
    margin-bottom: $gutter-2;
    padding: $gutter-2 $gutter-1;
    font-family: $font-1;
    background-color: $color-4;
    width: 100%;
    font-size: 1rem;
  }

  &__field::placeholder {
    font-style: italic;
  }

  &__buttons {
    min-width: 70%;
    display: flex;
    justify-content: space-evenly;

    &__item {
      border: $border-size-1 solid $color-1;
      padding: $gutter-1 $gutter-2;
      background-color: $color-2;
      color: $color-3;
      font-family: $font-1;
      font-size: 1rem;
      transition: all $duration-2;
      box-shadow: $shadow-1;
      cursor: pointer;
      
      &:hover {
        transition: all $duration-1;
        background-color: $color-7;
          color: $color-3;
      }

      &--cancel {
        background-color: $color-5;
        color: $color-2;

        &:hover {
          background-color: $color-7;
          color: $color-3;
        }
      }

      
    }
  }

  &__flash {
    margin-bottom: $gutter-2;
    display: flex;
    align-items: center;

    &--error {
      color: $color-error;
      &:before {
        content: url(../assets/error.svg);
        margin-right: $gutter-3;
      }
    }

    &--success {
      color: $color-success;
      &:before {
        content: url(../assets/success.svg);
        margin-right: $gutter-3;
      }
    }
  }
}
