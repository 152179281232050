@import "settings";

.Skills {
  &__domain {
    padding: $gutter-1;
    &:not(:last-child) {
      border-bottom: $border-size-1 solid $color-1;
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      @media screen and (min-width: 768px) {
        max-width: 668px;
        margin: 0 auto;
      }
    }

    &__title {
      text-align: center;
      width: 100%;
      text-shadow: $shadow-1;
    }

    &__speciality {
      border: $border-size-1 dotted $color-1;
      margin-bottom: $gutter-1;
      display: inline-block;
      padding: $gutter-1;
      transition: all $duration-2;

      &__list {
        list-style: none;
      }

      &:hover {
        background-color: $color-7;
        transition: all $duration-1;
      }
    }
  }

  &__others {
    text-align: center;
    list-style: none;
  }
}
