@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400;700;900&display=swap);
*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.App{border:3px solid #535353;z-index:99;min-height:100vh}.App__content{margin-bottom:20vh}@media screen and (min-width: 767px){.App__content{margin-bottom:0;padding-bottom:11%}}@media screen and (min-height: 1000px) and (min-width: 768px){.App__content{padding-bottom:15%;margin-bottom:0}}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Header{font-family:"Playfair Display SC",serif;text-align:center;padding:.5rem}.Header__title{font-weight:900;font-size:2.5rem;text-shadow:0px 5px 4px rgba(65,65,65,0.313725);display:flex;justify-content:center;align-items:flex-end}@media screen and (min-width: 400px){.Header__title{font-size:3rem}}.Header__title__logo{height:2.5rem}@media screen and (min-width: 400px){.Header__title__logo{height:3rem}}.Header__subtitle{font-weight:700}.Header__town{font-size:0.8rem}.Header__date{font-family:"Courier Prime",monospace;margin-top:1.5rem}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.MainNav{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-evenly}.MainNav__item{cursor:pointer;width:33%;font-weight:bold;font-size:1.2rem;text-align:center;border-top:3px solid #414141;border-right:3px solid #414141;padding:1rem;display:flex;align-items:center;justify-content:center;position:relative}@media screen and (min-width: 768px){.MainNav__item{width:25%;border-bottom:3px solid #414141}}.MainNav__item:last-of-type{width:100%;border-right:none;border-bottom:3px solid #414141}@media screen and (min-width: 768px){.MainNav__item:last-of-type{width:25%;border-left:3px solid #414141}}.MainNav__item:nth-last-of-type(2){border-right:none}.MainNav__item p{position:relative;z-index:1;cursor:default}.MainNav__item svg{position:absolute;width:100%}.MainNav__item svg path{stroke:transparent;fill:transparent;transition-property:stroke-dashoffset;transition-duration:0;transition-timing-function:cubic-bezier(0.455, 0.03, 0.515, 0.955);stroke-width:6}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Footer{z-index:100;position:fixed;bottom:0;left:.5rem;right:.5rem;border:3px solid #414141;background-color:#535353;max-height:20%}.Footer__SecondaryNav{display:flex;flex-wrap:nowrap;justify-content:space-around;font-style:italic;font-weight:bold;font-size:0.8rem;border-bottom:3px solid #414141;background-color:#FFFBF1}.Footer__SecondaryNav__item{cursor:pointer;text-align:center;padding:.5rem}.Footer__SecondaryNav__item:before{content:"·"}.Footer__SecondaryNav__item:after{content:"·"}.Footer__SecondaryNav__item:hover{color:#676CEF}.Footer__SocialMedias{display:flex;flex-wrap:wrap;justify-content:center;padding:.5rem;background-color:#535353}.Footer__SocialMedias__item .logo{fill:#fff;box-shadow:0px 5px 4px rgba(65,65,65,0.313725)}.Footer__SocialMedias__item:not(:last-child){margin-right:1rem}.Footer__Copyright{text-align:center;padding:.5rem;color:#fff;border-top:3px solid #414141;background-color:#535353}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Home{padding:1.5rem 1rem}@media screen and (min-width: 768px){.Home{max-width:668px;margin:0 auto}}.Home__image{width:100%;margin-bottom:1rem}@media screen and (min-width: 768px){.Home__image{width:50%;float:left;margin-right:1rem}}.Home__presentation{margin-bottom:1.5rem}.Home__presentation em{font-weight:bold;font-size:1.2rem}.Home__action{display:flex;justify-content:space-evenly}.Home__action__button{border:3px solid #414141;padding:.5rem 1rem;background-color:#535353;color:#fff;transition:all 1.8s;box-shadow:0px 5px 4px rgba(65,65,65,0.313725);cursor:pointer}.Home__action__button:hover{transition:all .6s;background-color:#FFFBF1;color:#414141}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Job:not(:last-child){margin-bottom:1.5rem}.Job__title:before{content:"+";margin-right:.5rem}.Job__dates{text-transform:lowercase;font-style:italic;margin-bottom:.5rem}.Job__missions{padding-left:1.5rem}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Study{display:flex;align-items:flex-start;justify-content:space-between}.Study__content:not(:last-child){margin-bottom:1.5rem}.Study__content__title:before{content:url(/static/media/studies.9b8a47f3.svg);margin-right:.5rem}.Study__content__dates{text-transform:lowercase;font-style:italic;margin-bottom:.5rem}.Study__schoolLogo{width:100px;-webkit-filter:grayscale(100%);filter:grayscale(100%);transition:all 1.8s}.Study__schoolLogo:hover{transition:all .6s;-webkit-filter:none;filter:none}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Degree{display:flex;align-items:flex-start;justify-content:space-between}.Degree:not(:last-child){margin-bottom:1.5rem}.Degree__content__title:before{content:url(/static/media/certificate.83911bcc.svg);margin-right:.5rem}.Degree__content__year{font-style:italic;margin-bottom:.5rem}.Degree__labelLogo{width:100px;-webkit-filter:grayscale(100%);filter:grayscale(100%);transition:all 1.8s}.Degree__labelLogo:hover{transition:all .6s;-webkit-filter:none;filter:none}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}@media screen and (min-width: 1200px){.Career{max-width:1200px;margin:0 auto;display:flex;flex-wrap:wrap}}.Career__jobs{padding:.5rem}@media screen and (min-width: 1200px){.Career__jobs{width:70%;margin:auto;margin-bottom:1.5rem;border-bottom:3px solid #535353}}.Career__jobs__title{text-align:center;text-shadow:0px 5px 4px rgba(65,65,65,0.313725)}.Career__studies{padding:.5rem}@media screen and (min-width: 1200px){.Career__studies{width:50%;border-right:3px solid #535353}}.Career__studies__title{text-align:center;text-shadow:0px 5px 4px rgba(65,65,65,0.313725)}.Career__degrees{padding:.5rem}@media screen and (min-width: 1200px){.Career__degrees{width:50%}}.Career__degrees__title{text-align:center;text-shadow:0px 5px 4px rgba(65,65,65,0.313725)}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Skills__domain{padding:.5rem}.Skills__domain:not(:last-child){border-bottom:3px solid #414141}.Skills__domain__container{display:flex;flex-wrap:wrap;justify-content:space-around;align-items:center}@media screen and (min-width: 768px){.Skills__domain__container{max-width:668px;margin:0 auto}}.Skills__domain__title{text-align:center;width:100%;text-shadow:0px 5px 4px rgba(65,65,65,0.313725)}.Skills__domain__speciality{border:3px dotted #414141;margin-bottom:.5rem;display:inline-block;padding:.5rem;transition:all 1.8s}.Skills__domain__speciality__list{list-style:none}.Skills__domain__speciality:hover{background-color:#C4C4C4;transition:all .6s}.Skills__others{text-align:center;list-style:none}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Projects__item{padding:.5rem}.Projects__item__container{display:flex;flex-direction:column}@media screen and (min-width: 768px){.Projects__item__container{max-width:668px;margin:0 auto}}.Projects__item:not(:last-child){border-bottom:3px solid #414141;padding-bottom:3rem}.Projects__item__title{text-shadow:0px 5px 4px rgba(65,65,65,0.313725)}.Projects__item__title,.Projects__item__year{text-align:center}.Projects__item__preview{display:flex;align-items:center;justify-content:space-around;margin:1.5rem 0}.Projects__item__preview__desktop{width:70%}.Projects__item__preview__mobile{width:20%}.Projects__item__description{margin-bottom:1rem;text-align:justify;text-indent:1.5rem}.Projects__item__stack{font-weight:bold;margin-right:.5rem}.Projects__item__stack__techno{margin-right:.5rem}.Projects__item__stack__techno:not(:last-child){margin-right:.5rem}.Projects__item__stack__techno:not(:last-child):after{content:","}.Projects__item__stackList{list-style:none;display:flex;flex-wrap:wrap;justify-content:flex-start}.Projects__item__stackList,.Projects__item__stack{text-align:center;text-transform:capitalize}.Projects__item__button{border:3px solid #414141;padding:.5rem 1rem;background-color:#535353;color:#fff;transition:all 1.8s;box-shadow:0px 5px 4px rgba(65,65,65,0.313725);cursor:pointer;min-width:30%;margin:auto;text-align:center;margin-top:1.5rem;margin-bottom:1rem}.Projects__item__button:hover{transition:all .6s;background-color:#FFFBF1;color:#414141}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.Contact{padding:1.5rem 1rem;display:flex;flex-direction:column;align-items:center}@media screen and (min-width: 768px){.Contact{max-width:668px;margin:0 auto}}.Contact label{align-self:start}.Contact__field{margin-bottom:1rem;padding:1rem .5rem;font-family:"Courier Prime",monospace;background-color:rgba(196,196,196,0.313725);width:100%;font-size:1rem}.Contact__field::-webkit-input-placeholder{font-style:italic}.Contact__field:-ms-input-placeholder{font-style:italic}.Contact__field::placeholder{font-style:italic}.Contact__buttons{min-width:70%;display:flex;justify-content:space-evenly}.Contact__buttons__item{border:3px solid #414141;padding:.5rem 1rem;background-color:#535353;color:#fff;font-family:"Courier Prime",monospace;font-size:1rem;transition:all 1.8s;box-shadow:0px 5px 4px rgba(65,65,65,0.313725);cursor:pointer}.Contact__buttons__item:hover{transition:all .6s;background-color:#C4C4C4;color:#fff}.Contact__buttons__item--cancel{background-color:#FFFBF1;color:#535353}.Contact__buttons__item--cancel:hover{background-color:#C4C4C4;color:#fff}.Contact__flash{margin-bottom:1rem;display:flex;align-items:center}.Contact__flash--error{color:#F85353}.Contact__flash--error:before{content:url(/static/media/error.d0f93887.svg);margin-right:1.5rem}.Contact__flash--success{color:#88BA62}.Contact__flash--success:before{content:url(/static/media/success.1549c4bd.svg);margin-right:1.5rem}

*{padding:0;margin:0;box-sizing:border-box;border:none}a{text-decoration:none;color:inherit}body{background:url(/static/media/rice-paper-2.2334758e.png);background-attachment:fixed;background-size:auto;font-family:"Courier Prime",monospace;color:#414141;padding:.5rem;overflow-y:scroll}h2{font-family:"Playfair Display SC",serif;text-decoration:underline;margin-bottom:1rem}.NotFound{padding:1.5rem 1rem;display:flex;flex-direction:column;align-items:center}@media screen and (min-width: 768px){.NotFound{max-width:668px;margin:0 auto}}.NotFound__error{font-family:"Playfair Display SC",serif;font-size:8rem}.NotFound__message{text-align:center;margin-bottom:1.5rem}.NotFound__button{border:3px solid #414141;padding:.5rem 1rem;background-color:#535353;color:#fff;transition:all 1.8s;box-shadow:0px 5px 4px rgba(65,65,65,0.313725);cursor:pointer}.NotFound__button:hover{transition:all .6s;background-color:#FFFBF1;color:#414141}

