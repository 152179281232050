@import "settings";

.Header {
  font-family: $font-2;
  text-align: center;
  padding: $gutter-1;

  &__title {
    font-weight: 900;
    font-size: 2.5rem;
    text-shadow: $shadow-1;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media screen and (min-width: 400px) {
      font-size: 3rem;
    }

    &__logo {
      height: 2.5rem;

      @media screen and (min-width: 400px) {
        height: 3rem;
      }
    }
  }

  &__subtitle {
    font-weight: 700;
  }

  &__town {
    font-size: 0.8rem;
  }

  &__date {
    font-family: $font-1;
    margin-top: $gutter-3;
  }
}
