@import "settings";

.MainNav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  &__item {
    cursor: pointer;
    width: 33%;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    border-top: $border-size-1 solid $color-1;
    border-right: $border-size-1 solid $color-1;
    padding: $gutter-2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (min-width: 768px) {
      width: 25%;
      border-bottom: $border-size-1 solid $color-1;
      
    }

    &:last-of-type {
      width: 100%;
      border-right: none;
      border-bottom: $border-size-1 solid $color-1;

      @media screen and (min-width: 768px) {
        width: 25%;
        border-left: $border-size-1 solid $color-1;
      }
    }

    &:nth-last-of-type(2) {
      border-right: none;
    }

    p {
      position: relative;
      z-index: 1;
      cursor: default;
    }

    svg {
      position: absolute;
      width: 100%;
    }

    svg path {
      stroke: transparent;
      fill: transparent;
      transition-property: stroke-dashoffset;
      transition-duration: 0;
      transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      stroke-width: 6
    }
  }
}
