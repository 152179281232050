@import 'settings';

.Job {
  &:not(:last-child) {
    margin-bottom: $gutter-3;
  }

  &__title:before {
    content: "+";
    margin-right: $gutter-1;
  }

  &__dates {
    text-transform: lowercase;
    font-style: italic;
    margin-bottom: $gutter-1;
  }

  &__missions {
    padding-left: $gutter-3;
  }
}