@import "settings";

.Footer {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: $gutter-1;
  right: $gutter-1;
  border: $border-size-1 solid $color-1;
  background-color: $color-2;
  max-height: 20%;

  &__SecondaryNav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    font-style: italic;
    font-weight: bold;
    font-size: 0.8rem;
    border-bottom: $border-size-1 solid $color-1;
    background-color:$color-5;

    &__item {
      cursor: pointer;
      text-align: center;
      padding: $gutter-1;

      &:before {
        content: "·";
      }

      &:after {
        content: "·";
      }

      &:hover {
        color: $color-6;
      }
    }
  }

  &__SocialMedias {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: $gutter-1;
    background-color: $color-2;

    &__item {
      .logo {
        fill: $color-3;
        box-shadow: $shadow-1;
      }

      &:not(:last-child) {
        margin-right: $gutter-2;
      }
    }
  }

  &__Copyright {
    text-align: center;
    padding: $gutter-1;
    color: $color-3;
    border-top: $border-size-1 solid $color-1;
    background-color: $color-2;
  }
}
